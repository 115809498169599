import blazing_saddles from './assets/what_the_hell_blazing_saddles.gif';

import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={blazing_saddles} alt="What the heck..."/>
        <p>
          I'll upload some shit here soon...
        </p>
      </header>
    </div>
  );
}

export default App;
